<template>
  <div class="question__wrapper">
    <div class="container">
      <div class="question__inner">
        <div class="question__header">
          <div class="question__tags">
            <div
              v-for="tag in question.tags"
              :key="tag.id"
              class="question__tag indicator"
              :class="{
                'indicator--blue indicator--fill': tag.name === 'Shop',
                'indicator--green indicator--fill': tag.name === 'Farm'
              }"
            >{{ tag.name }}</div>
          </div>
          <div class="question__title">{{ question.title }}</div>
          <div class="question__info">
            <div class="question__author">
              <span class="author__photo" :style="{backgroundImage: 'url(' + image + ')'}"></span>
              <span class="author__name">{{ question.author }}</span>
            </div>
            <div class="question__comments">
              <span class="question__date">{{ question.date }}</span>
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g opacity="0.3">
                  <path
                    d="M12.6 0H1.4C1.0287 0 0.672601 0.1475 0.41005 0.41005C0.1475 0.672601 0 1.0287 0 1.4V14L2.8 11.2H12.6C12.9713 11.2 13.3274 11.0525 13.5899 10.7899C13.8525 10.5274 14 10.1713 14 9.8V1.4C14 0.623 13.37 0 12.6 0Z"
                    fill="#172B4D"
                  />
                </g>
              </svg>
              <span class="question__comment">{{ question.comments }}</span>
            </div>
          </div>
        </div>
        <div class="question__answer answer__wrapper">
          <div class="answer__inner" @click="openModal">
            <div class="answer">Got an answer?</div>
          </div>
        </div>
        <div class="question__answers answers__wrapper">
          <div class="answers__inner">
            <div class="answers__header">
              <div class="answers__header-left">Recent Answers</div>
              <div class="answers__header-right">sort by: oldest</div>
            </div>
            <div class="answers__main">
              <Card v-for="answer in question.answers" :key="answer.id" :answer="answer" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Card from '../Answer'
import Burger from '@/assets/icons/photo1.jpg'
import modal from './modal'

export default {
  name: 'Question',
  components: {
    Card
  },
  data: () => ({
    image: Burger,
    question: {
      id: 1,
      tags: [
        {
          id: 1,
          name: 'Shop'
        },
        {
          id: 2,
          name: 'Farm'
        }
      ],
      title: 'How can I build the most competitive headgate?',
      comments: 23,
      date: '13 Sep 2020 at 14.21 PM',
      author: 'Burger with Bacon',
      photo: 'Burger',
      answers: [
        {
          id: 1,
          author: 'None',
          photo: 'None',
          date: '13 Sep 2020 at 14.21 PM',
          answer:
            "Use dragons! That’s what I did! There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc."
        },
        {
          id: 2,
          author: 'None',
          photo: 'None',
          date: '13 Sep 2020 at 14.21 PM',
          answer:
            "Use dragons! That’s what I did! There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc."
        }
      ]
    }
  }),
  methods: {
    openModal() {
      const style = this.$modal.styles
      this.active = !this.active
      this.$modal.show(
        modal,
        {},
        {...style, width: 440},
        {
          'before-open': this.$modal.open,
          'before-close': this.$modal.close
        }
      )
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'style';
</style>
