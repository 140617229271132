<template>
  <div class="answer__wrapper">
    <div class="answer__title">Got any good anwer? Please share it with the community!</div>
    <div class="answer__area-wrapper">
      <textarea class="answer__area" placeholder="Please type your answer here..."></textarea>
    </div>
    <div class="answer__button button button--fill">Let’s get it posted!</div>
  </div>
</template>

<script>
export default {
  name: 'AnswerModal'
}
</script>

<style lang="scss" scoped>
@import 'style.scss';
</style>
