<template>
  <div class="answer__wrapper">
    <div class="answer__inner">
      <div class="answer__header">
        <div class="answer__info">
          <div class="answer__author">
            <span class="author__photo" :style="{backgroundImage: 'url(' + image + ')'}"></span>
            <span class="author__name">{{ answer.author }}</span>
          </div>
          <div class="answer__date">{{ answer.date }}</div>
        </div>
      </div>
      <div class="answer__main">{{ answer.answer }}</div>
    </div>
  </div>
</template>

<script>
import Burger from '@/assets/icons/photo1.jpg'

export default {
  name: 'Answer',
  props: {
    answer: {
      type: Object,
      default: null
    }
  },
  data: () => ({
    image: Burger
  })
}
</script>

<style lang="scss" scoped>
@import 'style.scss';
</style>
